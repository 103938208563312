// Generated by Framer (05fbd34)

import { addFonts, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/QIhhOiDJA";
const RichTextWithFX = withFX(RichText);

const cycleOrder = ["kzdwUTKl4"];

const serializationHash = "framer-v3C3R"

const variantClassNames = {kzdwUTKl4: "framer-v-1ycdh6n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const animation = {opacity: 0, rotate: 0, scale: 1, x: 0, y: 70}

const transition1 = {damping: 70, delay: 0, mass: 1, stiffness: 300, type: "spring"}

const animation1 = {opacity: 0, rotate: 0, scale: 1, transition: transition1, x: 0, y: 70}

const transformTemplate1 = (_, t) => `perspective(1200px) translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kzdwUTKl4", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ycdh6n", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kzdwUTKl4"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichTextWithFX __framer__animate={{transition: transition1}} __framer__animateOnce __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __fromCanvasComponent __perspectiveFX={false} __smartComponentFX __targetOpacity={1} children={<React.Fragment><motion.h2 className={"framer-styles-preset-19vmbtv"} data-styles-preset={"QIhhOiDJA"} style={{"--framer-text-color": "var(--extracted-1of0zx5, rgb(0, 0, 0))"}}><motion.span data-text-fill={"true"} style={{backgroundImage: "linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(199, 228, 255) 100%)"}}>Доступные направления</motion.span></motion.h2></React.Fragment>} className={"framer-gdrq7q"} layoutDependency={layoutDependency} layoutId={"lx9jFpcN4"} style={{"--extracted-1of0zx5": "rgb(0, 0, 0)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-v3C3R[data-border=\"true\"]::after, .framer-v3C3R [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-v3C3R.framer-1a0vsk4, .framer-v3C3R .framer-1a0vsk4 { display: block; }", ".framer-v3C3R.framer-1ycdh6n { height: 66px; overflow: hidden; position: relative; width: 1160px; }", ".framer-v3C3R .framer-gdrq7q { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre-wrap; width: 1160px; word-break: break-word; word-wrap: break-word; z-index: 1; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 66
 * @framerIntrinsicWidth 1160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerYzWn1MELA: React.ComponentType<Props> = withCSS(Component, css, "framer-v3C3R") as typeof Component;
export default FramerYzWn1MELA;

FramerYzWn1MELA.displayName = "направления";

FramerYzWn1MELA.defaultProps = {height: 66, width: 1160};

addFonts(FramerYzWn1MELA, [...sharedStyle.fonts])